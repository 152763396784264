import React from 'react';
import PropTypes from 'prop-types';
import { Disqus as DisqusCommentBox } from 'gatsby-plugin-disqus';

import useLocation from '@zd/hooks/useLocation';

const Disqus = props => {
  const { identifier, title, ...rest } = props;

  const location = useLocation();

  const config = {
    url: location.href,
    identifier,
    title,
  };

  return <DisqusCommentBox config={config} {...rest} />;
};

Disqus.propTypes = {
  identifier: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Disqus;
