import React from 'react';
import styled from '@emotion/styled';

import useLocation from '@zd/hooks/useLocation';
import Link from '@zd/components/link';
import theme from '@zd/theme';

const WrapperDiv = styled.div`
  justify-content: flex-start;
  color: ${theme.colors.mainGrey};
  font-size: 0.8rem;
  font-weight: 400;

  ${theme.medias.gteSmall} {
    font-size: 1rem;
    display: flex;
  }

  > span {
    font-weight: 600;
    margin-right: 0.5rem;
    color: #592cba;
  }
`;

const Ul = styled.ul`
  display: flex;
`;

const Li = styled.li`
  margin: 0;

  &:not(:last-of-type) {
    &:after {
      content: '•';
      margin: 0 0.5rem;
    }
  }

  &:last-of-type {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
  }
`;

const ShareBox = () => {
  const location = useLocation();

  return (
    <WrapperDiv>
      <span>Partilhar:</span>
      <Ul>
        <Li>
          <Link target="_blank" href={`https://facebook.com/sharer.php?u=${location.href}`}>
            Facebook
          </Link>
        </Li>
        <Li>
          <Link
            target="_blank"
            href={`https://linkedin.com/shareArticle/?mini=true&url=${location.href}`}
          >
            LinkedIn
          </Link>
        </Li>
        <Li>
          <Link target="_blank" href={`https://twitter.com/intent/tweet?url=${location.href}`}>
            Twitter
          </Link>
        </Li>
      </Ul>
    </WrapperDiv>
  );
};

export default ShareBox;
