/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import PageTitle from '@zd/components/page-title';

import PageSEO from '@zd/components/page-seo';
import { Container } from '@zd/components/grid';
import HtmlContent from '@zd/components/html-content';
import Disqus from '@zd/components/social-embeds/disqus';
import ShareBox from '@zd/components/share-box';
import LazyImage from '@zd/components/lazy-image';
import Link from '@zd/components/link';
import InterestedCta from '@zd/components/interested-cta';
import formatDate from '@zd/utils/formatDate';

import theme from '@zd/theme';

const DateDiv = styled.div`
  color: #b1acbd;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-transform: uppercase;

  ${theme.medias.gteLarge} {
    font-size: 14px;
    line-height: 32px;
  }
`;

const ImgDiv = styled.div`
  padding-right: 15px;
  padding-left: 15px;

  margin-bottom: 1rem;

  ${theme.medias.gteLarge} {
    padding: 0 2rem;
  }
`;

const StyledLazyImage = styled(LazyImage)`
  width: 100%;
  object-fit: cover;
  max-height: 400px;
  border-radius: 20px;
  border: 1px solid ${theme.colors.itemBorder};
  background: ${theme.colors.itemBorder};
`;

const ContentDiv = styled.div`
  ${theme.medias.gteLarge} {
    padding: 0 150px;
  }
`;

const BackLink = styled(Link)`
  display: inline-block;
  color: #592cba;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
`;

const PageTitleDiv = styled.div``;

const PageTitleH1 = styled.h1`
  color: ${theme.colors.primaryBlue};
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 42px;
  text-align: left;
  margin-bottom: 1rem;
`;

const PageTitleDividerDiv = styled.div`
  width: 100px;
  height: 5px;
  background: ${theme.colors.secondaryBlue};
  margin: 0;
  margin-bottom: 0.5rem;
`;

const SmallTimeToRead = styled.small`
  display: inline-block;
  margin-bottom: 1.5rem;
`;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { sourceName: { eq: "blog-posts" }, slug: { eq: $slug } }) {
      excerpt(pruneLength: 250)
      html
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
        categories
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default props => {
  const blogPost = props.data.markdownRemark;

  const slug = blogPost.fields.slug;
  const title = blogPost.frontmatter.title;
  const date = blogPost.frontmatter.date;
  const thumbnail = blogPost.frontmatter.thumbnail.childImageSharp.fluid;
  const html = blogPost.html;
  const excerpt = blogPost.excerpt;
  const timeToRead = blogPost.timeToRead;

  return (
    <>
      <PageSEO {...props} title={title} description={excerpt} thumbnail={thumbnail.src} />
      <ImgDiv>
        <StyledLazyImage fluid={thumbnail} alt={title} />
      </ImgDiv>
      <Container>
        <ContentDiv>
          <DateDiv>{formatDate(date)}</DateDiv>
          <PageTitleDiv>
            <PageTitleH1>{title}</PageTitleH1>
            <PageTitleDividerDiv />
          </PageTitleDiv>
          <SmallTimeToRead>{timeToRead} min de leitura</SmallTimeToRead>
          <HtmlContent html={html} />
          <br />
          <ShareBox />
          <br />
          {/*
        <Disqus identifier={slug} title={title} />
        <br />
        */}
          <BackLink href="/blog" title="Voltar ao blog">
            ← Voltar ao blog
          </BackLink>
          <br />
          <br />
        </ContentDiv>
        <InterestedCta />
        <br />
      </Container>
    </>
  );
};
